/* src/components/Home.module.css */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    text-align: center;
    background-color: #f5f5f5;
    overflow: hidden; /* Prevents overflow issues */
  }
  
  .intro {
    margin-bottom: 2rem;
    opacity: 0; /* Start hidden */
    animation: fadeIn 1s forwards;
  }
  
  .intro h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .fadeIn {
    opacity: 1;
    animation: fadeIn 1s forwards;
  }
  
  .fadeOut {
    opacity: 0;
    animation: fadeOut 1s forwards;
  }
  
  .descriptionSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
    animation: fadeIn 2s forwards;
  }
  
  @media (min-width: 768px) {
    .descriptionSection {
      flex-direction: row;
    }
  }
  
  .description {
    font-size: 1.2rem;
    color: #666;
    opacity: 0; /* Start hidden */
    animation: fadeIn 2s forwards;
    text-align: left;
    margin: 0 1rem; /* Add margin to ensure they come into view */
  }
  
  .photo {
    width: 100%;
    max-width: 300px;
    opacity: 0; /* Start hidden */
    animation: fadeIn 2s forwards;
    margin: 0 1rem; /* Add margin to ensure they come into view */
  }
  
  .photo img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .fadeIn {
    opacity: 1;
  }
  
  .fadeOut {
    opacity: 0;
  }
  