/* src/index.css */
@import url('https://use.typekit.net/cfo5ffp.css');

body {
  font-family: 'futura-pt', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

/* Other global styles */
