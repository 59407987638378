/* src/components/Contact.module.css */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
    opacity: 0; /* Start hidden */
    transition: opacity 1s ease-in-out; /* Smooth transition */
  }
  
  .fadeIn {
    opacity: 1;
    animation: fadeIn 1s forwards;
  }
  
  .contactForm {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .formGroup {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submitButton {
    background-color: #000; /* Set background to black */
    color: #fff; /* Set text color to white */
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .submitButton:hover {
    background-color: #333; /* Darker black on hover */
  }
  
  .success {
    color: green;
    margin-bottom: 1rem;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
  }
  