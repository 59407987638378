/* src/components/HamburgerMenu.module.css */

.hamburgerMenu {
  position: relative;
  z-index: 1000;
}

.hamburgerIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  transition: all 0.3s ease;
}

.bar1 {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.bar2 {
  opacity: 0;
}

.bar3 {
  transform: rotate(45deg) translate(-5px, -5px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 999;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.menuOpen {
  display: flex;
  opacity: 1;
}

.menu li {
  margin: 20px 0;
}

.menu li a {
  color: white;
  text-decoration: none;
  font-size: 2rem;
}

.menu li a:hover {
  text-decoration: underline;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}
