/* src/components/Resume.module.css */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
    opacity: 0; /* Start hidden */
    transition: opacity 1s ease-in-out; /* Smooth transition */
  }
  
  .fadeIn {
    opacity: 1;
    animation: fadeIn 1s forwards;
  }
  
  .pdfViewer {
    width: 100%;
    max-width: 1000px; /* Increase max width */
    height: 90vh; /* Increase height */
    margin: 0 auto; /* Center the viewer */
    border: 1px solid #ddd; /* Optional: Add a border for better visibility */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  }
  