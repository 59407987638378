/* src/components/Projects.module.css */
@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
    opacity: 0; /* Start hidden */
    transition: opacity 1s ease-in-out; /* Smooth transition */
  }
  
  .fadeIn {
    opacity: 1;
    animation: fadeIn 1s forwards;
  }
  
  h2 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .project {
    margin-bottom: 2rem;
    text-align: left;
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  ul {
    font-size: 1.2rem;
    color: #666;
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .githubProjects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .repo {
    background-color: #fff;
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: left;
  }
  
  .repo h4 a {
    color: #007bff;
    text-decoration: none;
  }
  
  .repo h4 a:hover {
    text-decoration: underline;
  }
  
  .repo p {
    color: #666;
    font-size: 1rem;
  }
  